@font-face {
  font-family: 'AvertaPE Bold';
  src: url('/src/fonts/AvertaPE-Bold.otf') format('opentype');
  font-display: swap;
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'AvertaPE Regular';
  src: url('/src/fonts/AvertaPE-Regular.otf') format('opentype');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvertaPE Semibold';
  src: url('/src/fonts/AvertaPE-Semibold.otf') format('opentype');
  font-display: swap;
  font-weight: 600;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'AvertaPE Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  font-size: 16px;
  background: #f3f4f6;
}

code {
  font-family: 'AvertaPE Regular', sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
ul,
li {
  list-style: none;
  text-decoration: none;
}
